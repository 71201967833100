<template>
  <transition name="modal-fade">
    <div v-if="visible" class="modal-overlay" @click.self="close">
      <div class="modal-container">
        <div class="modal-header">
          <h5 class="modal-title">{{ titleText }}</h5>
          <button type="button" class="close-btn" @click="close">
            <img src="@/assets/icons/close-icon.svg" alt="Закрыть" width="16" height="16">
          </button>
        </div>

        <div class="modal-body">
          <div class="owner-info">
            <p>{{ descriptionText }}</p>
            <div class="owner-name">
              <img src="@/assets/icons/user-card-icon.svg" alt="Пользователь" width="20" height="20">
              <span>{{ ownerName }}</span>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-primary" @click="close">
            Обновить
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CheckOwnerBeforeTransfer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ownerName: {
      type: String,
      default: ''
    },
    entityType: {
      type: String,
      default: 'req', // 'req' или 'client'
      validator: value => ['req', 'client'].includes(value)
    }
  },
  computed: {
    titleText() {
      return this.entityType === 'req'
          ? 'Заявка уже в работе'
          : 'Клиент уже в работе';
    },
    descriptionText() {
      return this.entityType === 'req'
          ? 'Эта заявка уже принадлежит сотруднику:'
          : 'Этот клиент уже принадлежит сотруднику:';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 15px;
}

.modal-container {
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.close-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.modal-body {
  padding: 20px;
}

.owner-info {
  text-align: center;
}

.owner-name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
}

.owner-name img {
  margin-right: 8px;
}

.modal-footer {
  padding: 16px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: center;
}

.btn {
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: 500;
}

.btn-primary {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}
</style>